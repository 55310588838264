<template>
<div id="kampo">

  <div class="hidden-sm-and-down">
    <PageHeader/>
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <DepartmentAside aside-color="#7887d9" title="ダイエット">
          <ul class="nav">
            <li :class="showIndex == (index+1) ? 'active': ''" @click="changeNav(item)" v-for="(item,index) in navList" :key="index">{{item.name}}</li>
          </ul>
          <div class="detail1" v-if="showIndex == 1">
            <div class="img">
              <!-- スターターキット<br>リベルサス3mg/10錠     ¥2,980(税込) -->
              <p>見た目が変われば人生が変わる<br>GLP-1ストレスフリーダイエット</p>
              <el-image :src="require(`@/assets/departments/rwbanner.png`)"/>
            </div>
            <div class="content1">
              <ul class="jgb">
                <li v-for="(item,index) in jgList" :key="index">
                  <div class="y_name"><span>{{item.yname}}</span><span>{{item.yml}}</span></div>
                  <div class="y_num"><a>¥{{item.yyj}}</a><span>(税込)</span></div>
                  <div class="y_num"><b class="jt">▶</b>¥{{item.yxj}}<span>(税込)</span></div>
                </li>
              </ul>
            </div>
            <div class="img">
              <el-image :src="require(`@/assets/departments/rw02.png`)"/>
            </div>
            <div class="content1">
              <div class="title1">GLP-1 あなたの体質を変えられるとしたら</div>
              <div class="text">GLP-1は「痩せホルモン」ともよばれている、もともと人の体内に存在し腸から分泌されるホルモンのことで、主にインスリン分泌を促す働きをします。このGLP-1の分泌量が多いと食欲が抑えられ、脂肪が分解しやすいので太りにくく痩せやすいと言われています。逆にGLP-1の分泌量が少ないと、食欲が抑えられず食べ過ぎるので、結果的に太りやすくなります。</div>
              <div class="text">この痩せホルモンを毎日体内に取り込むだけで、体質を改善して自然にダイエットできるのが「GLP-1ダイエット」です。</div>
            </div>
            <div class="content1">
              <div class="title1">GLP-1 とは小腸から分泌されるホルモン</div>
              <div class="text">GLP-1とは、食事によって小腸から分泌されるホルモンの一種で、血糖値の上昇を抑えるインスリンの分泌を促します。また血糖値を上げるグルカゴンの分泌は抑えます。つまり血糖値を上がらないようにする薬剤、とお考え下さい。</div>
              <div class="text">さらにGLP-1には胃の内容物の排出を遅らせ、また食欲を抑える働きもあるためダイエットに効果的な薬剤として広く用いられるようになりました。</div>
            </div>
            <div class="content1">
              <div class="title1">GLP-1 ダイエットの特徴</div>
              <div class="text">血糖を司るホルモン分泌をコントロールし、血糖上昇を抑える<br>胃の内容物をゆっくりと腸へ送り込む</div>
            </div>
            
            <div class="tdimg">
              <el-image :src="require(`@/assets/departments/td01.jpg`)"/>
              <el-image :src="require(`@/assets/departments/td02.jpg`)"/>
              <el-image :src="require(`@/assets/departments/td03.jpg`)"/>
              <el-image :src="require(`@/assets/departments/td04.jpg`)"/>
            </div>
            <div class="content1">
              <div class="title1">01.ストレスのない食事制限</div>
              <div class="text">食事制限を行うダイエット方法の場合、食欲を無理に抑えると、人によってはストレスになってしまいます。</div>
              <div class="text">GLP-1には食欲抑制効果があり、使用することで食べすぎを防ぐだけでなくお腹がすきにくくなるため無駄な間食を防ぐこともできます。</div>
            </div>
            <div class="content1">
              <div class="title1">02.胃や腸に働きかけ糖の吸収を抑える</div>
              <div class="text">GLP-1は胃の内容物をゆっくりと排出させる作用があります。</div>
              <div class="text">そのため糖の吸収も穏やかになり血糖値の上昇を抑えることができます。</div>
            </div>
            <div class="content1">
              <div class="title1">03.血糖値を下げる働きがある</div>
              <div class="text">食事をとると一時的に血糖値が上昇します。その際にGLP-1が少ない方は、血糖値の上昇を抑制するインスリンの分泌が少なくなるため血糖値をうまくコントロールすることができません。</div>
              <div class="text">GLP-1を投与することで血糖コントロールが改善されます。</div>
            </div>
            <div class="content1">
              <div class="title1">04.シンプルなダイエット方法</div>
              <div class="text">錠剤タイプのＧＬＰ-１ダイエットは１日１錠、起床時、空腹の状態で服用するだけの、理想的でシンプルなダイエット方法です。</div>
              <div class="text">そのため誰でも簡単に継続して行うことができます。</div>
            </div>

            <div class="tdimg">
              <el-image :src="require(`@/assets/departments/y01.jpg`)"/>
              <el-image :src="require(`@/assets/departments/y02.jpg`)"/>
              <el-image :src="require(`@/assets/departments/y03.jpg`)"/>
            </div>
            <div class="content1">
              <div class="title1">リベルサス (GLP-1ホルモン)</div>
              <div class="text">多くの方がダイエットの経験があると思いますが、食事制限をしたり、無理な運動をすることは、精神的にストレスもあり、体にも負担が大きく継続が難しく途中で断念してしまうことが多いです。GLP-1ホルモンを内服するダイエット方法では、1日1回の内服で自然に食欲が抑えられ、ダイエットで一番つらい「空腹感」が減少し無理なくダイエットが続けられます。</div>
              <div class="text">それに加えてカナグルを一緒に内服すると、糖を吸収せず、尿から排出する働きがあるため、糖質を摂取しても吸収されにくくなります。</div>
            </div>
            <div class="content1">
              <div class="title1">カナグル (SGLT2阻害薬)</div>
              <div class="text">リベルサスと一緒にカナグルを内服することで、よりダイエット効果を高めることが期待できます。本来人間の体は、腎臓で血液から尿をつくる際に糖が尿に混ざってしまいます。しかし、腎臓で尿から糖を回収し、尿には糖が含まれない仕組みになっております。そこで、SGLT2阻害薬を内服することで、腎臓で尿から糖を回収する働きを阻害することで、糖が尿から排出されるようになります。</div>
            </div>
            <div class="content1">
              <div class="title1">防風通聖散 (漢方薬)</div>
              <div class="text">特に肥満症で便秘がちな人によく用いられ、"代謝・利尿・便通などの作用"により腹部の皮下脂肪を減らし、肥満症、便秘、肩こり、むくみなどに効果を表します。皮下脂肪だけでなく内臓脂肪を燃焼させ、肥満を改善しやすい体質を作るのにも有効なお薬です。この18種類の生薬は、おなか周りの脂肪や肥満症に効果がある成分。</div>
            </div>
          </div>
          <div class="detail1" v-if="showIndex == 2">
            <div class="img">
              <el-image :src="require(`@/assets/departments/jgb.jpg`)"/>
            </div>
          </div>
          <div class="detail1" v-if="showIndex == 3">
            <div class="content1">
              <div class="title1">診療からお支払いまでの流れ</div>
              <div class="text">当医院ではオンライン診療と直接のご来院どちらでもGLP-1の処方を行っており​下記の手順で受診いただけます。</div>
            </div>
            <div class="content1">
              <div class="title1">オンラインでの診療をご希望の場合</div>
              <div style="display:flex;">
                <ul>
                  <li><div class="text">1.<p>QRコードをスッケンして、必要事項を入力しお申し込み下さい。最初は登録が必要です。現在より翌日から、二週間後まで予約が可能となります。</p></div></li>
                  <li><div class="text">2.<p>ご予約の日時になりましたら、お申し込み頂いた電話でご連絡を差し上げます。</p></div></li>
                  <li><div class="text">3.<p>app.rapha99.comのオンランビデオ診察室でカウンセリングの後、医師による診療を行います。</p></div></li>
                  <li><div class="text">4.<p>お申し込みいただいた住所もしくはご指定頂いた送付先宛てに、当院からお薬を発送します。</p></div></li>
                  <li><div class="text">5.<p>オンラインでクレジットカードで支払い</p></div></li>
                  <li><div class="text">6.<p>お薬をお受け取り下さい。</p></div></li>
                </ul>
                <div class="qr-code">
                  <img src="../../assets/departments/rapha99_rwQR.jpg" alt="" width="220">
                  <div class="link" @click="download(PatientManual, '患者用操作マニュアル.pdf')">患者用操作マニュアル<br>ダウンロード</div>
                </div>
              </div>
            </div>
            <div class="content1">
              <div class="title1">外来での診療をご希望の場合</div>
              <ul>
                <li><div class="text">1.<p>QRコードをスッケンして、必要事項を入力しお申し込み下さい。現在より二週間後まで予約が可能となります。</p></div></li>
                <li><div class="text">2.<p>ご予約の日時に遅れないようにご来院ください。</p></div></li>
                <li><div class="text">3.<p>お時間になりましたらカウンセリングの後、医師による診療を行います。</p></div></li>
                <li><div class="text">4.<p>お薬をお渡しいたします。<br>お支払い方法
                <ol>
                  <li><div class="text">クレジットカード（VISA、マスター）</div></li>
                  <li><div class="text">QRコード</div></li>
                  <li><div class="text">現金</div></li>
                </ol></p></div></li>
              </ul>
            </div>
          </div>
          <div class="detail1" v-if="showIndex == 4">
            <div class="content1">
              <div class="title1">よくある質問</div>
              <div class="text"><span class="q">Q．</span><p>保険証の提示は必要ですか？</p></div>
              <div class="text"><span class="a">A．</span><p>自由診療のため特に必要はありません。</p></div>
            </div>
            <div class="content1">
              <div class="text"><span class="q">Q．</span><p>オンライン診療にどのくらい時間がかかりますか？</p></div>
              <div class="text"><span class="a">A．</span><p>カウンセリング、医師の診療を含め１５分ぐらいです。</p></div>
            </div>
            <div class="content1">
              <div class="text"><span class="q">Q．</span><p>診療予約の変更・キャンセルはいつまでできますか？</p></div>
              <div class="text"><span class="a">A．</span><p>ご予約日の前日までとなります</p></div>
            </div>
            <div class="content1">
              <div class="text"><span class="q">Q．</span><p>初診でもオンライン診療は可能ですか？</p></div>
              <div class="text"><span class="a">A．</span><p>はい、初診の患者様でもオンライン診療にてGLP-1をご注文いただけます。<br>こちらの診療予約から24時間予約を受け付けております。</p></div>
            </div>
            <div class="content1">
              <div class="text"><span class="q">Q．</span>GLP-1の薬(リベルサス)に副作用はありますか？</div>
              <div class="text"><span class="a">A．</span>
              <p>副作用としては、薬を飲み始めた際に、下記のような症状が報告されております。<br>胃もたれ、胸やけ、便秘、軽い吐き気、膨満感、倦怠感、など。<br>副作用には、個人差がありますが、治療を継続していくことで副作用は軽減しています。<br>ごくまれに、低血糖状態になり、冷や汗や立ちくらみが起こることがありますが、その際は糖分を摂取するようにしてください。症状はすぐに落ち着きます。</p>
              </div>
            </div>
            <div class="content1">
              <div class="text"><span class="q">Q．</span><p>GLP-1の薬(リベルサス)に誰でも治療できますか？</p></div>
              <div class="text"><span class="a">A．</span>
              <p>以下に該当される方は治療出来ません。
                <ul>
                  <li><div class="text">1.<p>膵炎などの膵臓疾患の既往、甲状腺疾患の既往、重度の胃腸障害（胃潰瘍、炎症性腸疾患など）のある方</p></div></li>
                  <li>
                    <div class="text">2.
                      <ul>
                        <li>ⅰ）脳下垂体機能不全又は副腎機能不全</li>
                        <li>ⅱ）栄養不良状態、飢餓状態、食事摂取量の不足</li>
                        <li>ⅲ）激しい筋肉運動</li>
                        <li>ⅳ）過度のアルコール摂取者</li>
                      </ul>
                    </div>
                  </li>
                  <li><div class="text">3.<p>糖尿病の方</p></div></li>
                  <li><div class="text">4.<p>妊娠中または2か月以内に妊娠する予定の方、未成年の方、60歳以上の方</p></div></li>
                </ul>
              </p>
              </div>
            </div>
            <div class="content1">
              <div class="text"><span class="q">Q．</span><p>これまでダイエットに失敗した人でも効果が得られますか？</p></div>
              <div class="text"><span class="a">A．</span><p>GLP-1リベルサスを内服することで、食欲を抑制し、長時間満腹感が続きますので食事の量が減少します。あとは、普段通り日常生活を送ることで消費カロリーと摂取カロリーの差によりダイエットが進んでいきます。これまでに食事制限や無理な運動をして続かずにダイエットが上手くいかなかった方も効果が期待できる治療方法です。</p></div>
            </div>
            <div class="content1">
              <div class="text"><span class="q">Q．</span><p>GLP-1を使ったダイエットは安全ですか？</p></div>
              <div class="text"><span class="a">A．</span><p>GLP-1ホルモンは、人の体に実際に存在しているホルモンです。すでに世界各国では肥満治療として実施されている安全性のある治療方法です。GLP-1の作用としては、食後などに血糖値が上昇した時のみにインスリンの分泌を促進する作用があるので、過剰にインスリン分泌を促進し、低血糖になるといったリスクもありません。</p></div>
            </div>
            <div class="content1">
              <div class="text"><span class="q">Q．</span><p>服用後いつから体重が減少し始めますか？</p></div>
              <div class="text"><span class="a">A．</span><p>リベルサスでは早ければ服用後3ヶ月程度で、体重減少の効果が見込めれます。<br>服用を途中でやめてしまうと、食欲低減などの効果はなくなってしまいます。<br>治療中止後にリバウンドする可能性があることに注意が必要です。</p></div>
            </div>
            <div class="content1">
              <div class="text"><span class="q">Q．</span><p>診療後、お薬はどれぐらいで届きますか？</p></div>
              <div class="text"><span class="a">A．</span><p>オンライン診療の場合、関東近辺の方は4日後程でお届け出来ると思います。<br>来院診療の場合はその場でお渡しできます。</p></div>
            </div>
          </div>
        </DepartmentAside>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-md-and-up">
    <m-top/>

    <div class="m-title">ダイエット</div>
    <div class="m-main">
      <ul class="m-nav">
        <li :class="showIndex == (index+1) ? 'active': ''" @click="changeNav(item)" v-for="(item,index) in navList" :key="index">{{item.name}}</li>
      </ul>
      <div class="m-detail" v-if="showIndex == 1">
            <div class="m-img">
              <!-- スターターキット<br>リベルサス3mg/10錠     ¥2,980(税込) -->
              <p>見た目が変われば人生が変わる<br>GLP-1ストレスフリーダイエット</p>
              <el-image :src="require(`@/assets/departments/rwbanner.png`)"/>
            </div>
            <div class="m-content">
              <ul class="jgb">
                <li v-for="(item,index) in jgList" :key="index">
                  <div class="y_name"><span>{{item.yname}}</span><span>{{item.yml}}</span></div>
                  <div class="y_num"><a>¥{{item.yyj}}</a><span>(税込)</span></div>
                  <div class="y_num"><b class="jt">▶</b>¥{{item.yxj}}<span>(税込)</span></div>
                </li>
              </ul>
            </div>
            <div class="m-img">
              <el-image :src="require(`@/assets/departments/rw02.png`)"/>
            </div>
            <div class="m-content">
              <div class="m-subtitle">GLP-1 あなたの体質を変えられるとしたら</div>
              <div class="m-content">GLP-1は「痩せホルモン」ともよばれている、もともと人の体内に存在し腸から分泌されるホルモンのことで、主にインスリン分泌を促す働きをします。このGLP-1の分泌量が多いと食欲が抑えられ、脂肪が分解しやすいので太りにくく痩せやすいと言われています。逆にGLP-1の分泌量が少ないと、食欲が抑えられず食べ過ぎるので、結果的に太りやすくなります。</div>
              <div class="m-content">この痩せホルモンを毎日体内に取り込むだけで、体質を改善して自然にダイエットできるのが「GLP-1ダイエット」です。</div>
            </div>
            <div class="m-content">
              <div class="m-subtitle">GLP-1 とは小腸から分泌されるホルモン</div>
              <div class="m-content">GLP-1とは、食事によって小腸から分泌されるホルモンの一種で、血糖値の上昇を抑えるインスリンの分泌を促します。また血糖値を上げるグルカゴンの分泌は抑えます。つまり血糖値を上がらないようにする薬剤、とお考え下さい。</div>
              <div class="m-content">さらにGLP-1には胃の内容物の排出を遅らせ、また食欲を抑える働きもあるためダイエットに効果的な薬剤として広く用いられるようになりました。</div>
            </div>
            <div class="m-content">
              <div class="m-subtitle">GLP-1 ダイエットの特徴</div>
              <div class="m-content">血糖を司るホルモン分泌をコントロールし、血糖上昇を抑える<br>胃の内容物をゆっくりと腸へ送り込む</div>
            </div>
            
            <div class="m-tdimg">
              <el-image :src="require(`@/assets/departments/td01.jpg`)"/>
              <el-image :src="require(`@/assets/departments/td02.jpg`)"/>
              <el-image :src="require(`@/assets/departments/td03.jpg`)"/>
              <el-image :src="require(`@/assets/departments/td04.jpg`)"/>
            </div>
            <div class="m-content">
              <div class="m-subtitle">01.ストレスのない食事制限</div>
              <div class="m-content">食事制限を行うダイエット方法の場合、食欲を無理に抑えると、人によってはストレスになってしまいます。</div>
              <div class="m-content">GLP-1には食欲抑制効果があり、使用することで食べすぎを防ぐだけでなくお腹がすきにくくなるため無駄な間食を防ぐこともできます。</div>
            </div>
            <div class="m-content">
              <div class="m-subtitle">02.胃や腸に働きかけ糖の吸収を抑える</div>
              <div class="m-content">GLP-1は胃の内容物をゆっくりと排出させる作用があります。</div>
              <div class="m-content">そのため糖の吸収も穏やかになり血糖値の上昇を抑えることができます。</div>
            </div>
            <div class="m-content">
              <div class="m-subtitle">03.血糖値を下げる働きがある</div>
              <div class="m-content">食事をとると一時的に血糖値が上昇します。その際にGLP-1が少ない方は、血糖値の上昇を抑制するインスリンの分泌が少なくなるため血糖値をうまくコントロールすることができません。</div>
              <div class="m-content">GLP-1を投与することで血糖コントロールが改善されます。</div>
            </div>
            <div class="m-content">
              <div class="m-subtitle">04.シンプルなダイエット方法</div>
              <div class="m-content">錠剤タイプのＧＬＰ-１ダイエットは１日１錠、起床時、空腹の状態で服用するだけの、理想的でシンプルなダイエット方法です。</div>
              <div class="m-content">そのため誰でも簡単に継続して行うことができます。</div>
            </div>

            <div class="tdimg">
              <el-image :src="require(`@/assets/departments/y01.jpg`)"/>
              <el-image :src="require(`@/assets/departments/y02.jpg`)"/>
              <el-image :src="require(`@/assets/departments/y03.jpg`)"/>
            </div>
            <div class="m-content">
              <div class="m-subtitle">リベルサス (GLP-1ホルモン)</div>
              <div class="m-content">多くの方がダイエットの経験があると思いますが、食事制限をしたり、無理な運動をすることは、精神的にストレスもあり、体にも負担が大きく継続が難しく途中で断念してしまうことが多いです。GLP-1ホルモンを内服するダイエット方法では、1日1回の内服で自然に食欲が抑えられ、ダイエットで一番つらい「空腹感」が減少し無理なくダイエットが続けられます。</div>
              <div class="m-content">それに加えてカナグルを一緒に内服すると、糖を吸収せず、尿から排出する働きがあるため、糖質を摂取しても吸収されにくくなります。</div>
            </div>
            <div class="m-content">
              <div class="m-subtitle">カナグル (SGLT2阻害薬)</div>
              <div class="m-content">リベルサスと一緒にカナグルを内服することで、よりダイエット効果を高めることが期待できます。本来人間の体は、腎臓で血液から尿をつくる際に糖が尿に混ざってしまいます。しかし、腎臓で尿から糖を回収し、尿には糖が含まれない仕組みになっております。そこで、SGLT2阻害薬を内服することで、腎臓で尿から糖を回収する働きを阻害することで、糖が尿から排出されるようになります。</div>
            </div>
            <div class="m-content">
              <div class="m-subtitle">防風通聖散 (漢方薬)</div>
              <div class="m-content">特に肥満症で便秘がちな人によく用いられ、"代謝・利尿・便通などの作用"により腹部の皮下脂肪を減らし、肥満症、便秘、肩こり、むくみなどに効果を表します。皮下脂肪だけでなく内臓脂肪を燃焼させ、肥満を改善しやすい体質を作るのにも有効なお薬です。この18種類の生薬は、おなか周りの脂肪や肥満症に効果がある成分。</div>
            </div>
          </div>
      <div class="m-detail" v-if="showIndex == 2">
        <div class="m-img">
          <el-image :src="require(`@/assets/departments/jgb.jpg`)"/>
        </div>
      </div>
      <div class="m-detail" v-if="showIndex == 3">
        <div class="m-content">
          <div class="m-subtitle">診療からお支払いまでの流れ</div>
          <div class="m-content">当医院ではオンライン診療と直接のご来院どちらでもGLP-1の処方を行っており​下記の手順で受診いただけます。</div>
        </div>
        <div class="m-content">
          <div class="m-subtitle">オンラインでの診療をご希望の場合</div>
          <ul>
            <li><div class="m-content">1.<p>QRコードをスッケンして、必要事項を入力しお申し込み下さい。最初は登録が必要です。現在より翌日から、二週間後まで予約が可能となります。</p></div></li>
            <li><div class="m-content">2.<p>ご予約の日時になりましたら、お申し込み頂いた電話でご連絡を差し上げます。</p></div></li>
            <li><div class="m-content">3.<p>app.rapha99.comのオンランビデオ診察室でカウンセリングの後、医師による診療を行います。</p></div></li>
            <li><div class="m-content">4.<p>お申し込みいただいた住所もしくはご指定頂いた送付先宛てに、当院からお薬を発送します。</p></div></li>
            <li><div class="m-content">5.<p>オンラインでクレジットカードで支払い</p></div></li>
            <li><div class="m-content">6.<p>お薬をお受け取り下さい。</p></div></li>
          </ul>
          <div class="m-qr-code">
            <img src="../../assets/departments/rapha99_rwQR.jpg" alt="" width="220">
            <div class="link" @click="download(PatientManual, '患者用操作マニュアル.pdf')">患者用操作マニュアル<br>ダウンロード</div>
          </div>
        </div>
        <div class="m-content">
          <div class="m-subtitle">外来での診療をご希望の場合</div>
          <ul>
            <li><div class="m-content">1.<p>QRコードをスッケンして、必要事項を入力しお申し込み下さい。現在より二週間後まで予約が可能となります。</p></div></li>
            <li><div class="m-content">2.<p>ご予約の日時に遅れないようにご来院ください。</p></div></li>
            <li><div class="m-content">3.<p>お時間になりましたらカウンセリングの後、医師による診療を行います。</p></div></li>
            <li><div class="m-content">4.<p>お薬をお渡しいたします。<br>お支払い方法
            <ol>
              <li><div class="m-content">クレジットカード（VISA、マスター）</div></li>
              <li><div class="m-content">QRコード</div></li>
              <li><div class="m-content">現金</div></li>
            </ol></p></div></li>
          </ul>
        </div>
      </div>
      <div class="m-detail" v-if="showIndex == 4">
            <div class="m-content">
              <div class="m-subtitle">よくある質問</div>
              <div class="m-content"><span class="q">Q．</span><p>保険証の提示は必要ですか？</p></div>
              <div class="m-content m-bot"><span class="a">A．</span><p>自由診療のため特に必要はありません。</p></div>
            </div>
            <div class="m-content">
              <div class="m-content"><span class="q">Q．</span><p>オンライン診療にどのくらい時間がかかりますか？</p></div>
              <div class="m-content m-bot"><span class="a">A．</span><p>カウンセリング、医師の診療を含め１５分ぐらいです。</p></div>
            </div>
            <div class="m-content">
              <div class="m-content"><span class="q">Q．</span><p>診療予約の変更・キャンセルはいつまでできますか？</p></div>
              <div class="m-content m-bot"><span class="a">A．</span><p>ご予約日の前日までとなります</p></div>
            </div>
            <div class="m-content">
              <div class="m-content"><span class="q">Q．</span><p>初診でもオンライン診療は可能ですか？</p></div>
              <div class="m-content m-bot"><span class="a">A．</span><p>はい、初診の患者様でもオンライン診療にてGLP-1をご注文いただけます。<br>こちらの診療予約から24時間予約を受け付けております。</p></div>
            </div>
            <div class="m-content">
              <div class="m-content"><span class="q">Q．</span>GLP-1の薬(リベルサス)に副作用はありますか？</div>
              <div class="m-content m-bot"><span class="a">A．</span>
              <p>副作用としては、薬を飲み始めた際に、下記のような症状が報告されております。<br>胃もたれ、胸やけ、便秘、軽い吐き気、膨満感、倦怠感、など。<br>副作用には、個人差がありますが、治療を継続していくことで副作用は軽減しています。<br>ごくまれに、低血糖状態になり、冷や汗や立ちくらみが起こることがありますが、その際は糖分を摂取するようにしてください。症状はすぐに落ち着きます。</p>
              </div>
            </div>
            <div class="m-content">
              <div class="m-content"><span class="q">Q．</span><p>GLP-1の薬(リベルサス)に誰でも治療できますか？</p></div>
              <div class="m-content m-bot"><span class="a">A．</span>
              <p>以下に該当される方は治療出来ません。
                <ul>
                  <li><div class="m-content">1.<p>膵炎などの膵臓疾患の既往、甲状腺疾患の既往、重度の胃腸障害（胃潰瘍、炎症性腸疾患など）のある方</p></div></li>
                  <li>
                    <div class="m-content">2.
                      <ul>
                        <li>ⅰ）脳下垂体機能不全又は副腎機能不全</li>
                        <li>ⅱ）栄養不良状態、飢餓状態、食事摂取量の不足</li>
                        <li>ⅲ）激しい筋肉運動</li>
                        <li>ⅳ）過度のアルコール摂取者</li>
                      </ul>
                    </div>
                  </li>
                  <li><div class="m-content">3.<p>糖尿病の方</p></div></li>
                  <li><div class="m-content">4.<p>妊娠中または2か月以内に妊娠する予定の方、未成年の方、60歳以上の方</p></div></li>
                </ul>
              </p>
              </div>
            </div>
            <div class="m-content">
              <div class="m-content"><span class="q">Q．</span><p>これまでダイエットに失敗した人でも効果が得られますか？</p></div>
              <div class="m-content m-bot"><span class="a">A．</span><p>GLP-1リベルサスを内服することで、食欲を抑制し、長時間満腹感が続きますので食事の量が減少します。あとは、普段通り日常生活を送ることで消費カロリーと摂取カロリーの差によりダイエットが進んでいきます。これまでに食事制限や無理な運動をして続かずにダイエットが上手くいかなかった方も効果が期待できる治療方法です。</p></div>
            </div>
            <div class="m-content">
              <div class="m-content"><span class="q">Q．</span><p>GLP-1を使ったダイエットは安全ですか？</p></div>
              <div class="m-content m-bot"><span class="a">A．</span><p>GLP-1ホルモンは、人の体に実際に存在しているホルモンです。すでに世界各国では肥満治療として実施されている安全性のある治療方法です。GLP-1の作用としては、食後などに血糖値が上昇した時のみにインスリンの分泌を促進する作用があるので、過剰にインスリン分泌を促進し、低血糖になるといったリスクもありません。</p></div>
            </div>
            <div class="m-content">
              <div class="m-content"><span class="q">Q．</span><p>服用後いつから体重が減少し始めますか？</p></div>
              <div class="m-content m-bot"><span class="a">A．</span><p>リベルサスでは早ければ服用後3ヶ月程度で、体重減少の効果が見込めれます。<br>服用を途中でやめてしまうと、食欲低減などの効果はなくなってしまいます。<br>治療中止後にリバウンドする可能性があることに注意が必要です。</p></div>
            </div>
            <div class="m-content">
              <div class="m-content"><span class="q">Q．</span><p>診療後、お薬はどれぐらいで届きますか？</p></div>
              <div class="m-content m-bot"><span class="a">A．</span><p>オンライン診療の場合、関東近辺の方は4日後程でお届け出来ると思います。<br>来院診療の場合はその場でお渡しできます。</p></div>
            </div>
          </div>
    </div>

    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
export default {
  name: "Kampo",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader},
  data(){
    return{
      PatientManual: './secondFile/患者用操作マニュアル.pdf',
      navList:[
        {id:'1',name:'ご紹介'},
        {id:'2',name:'価格一覧'},
        {id:'3',name:'診療からの流れ'},
        {id:'4',name:'よくある質問'},
        ],
      jgList:[
        {id:'1',yname:'リベルサス',yml:'3mg/30錠 ',yyj:'7,700',yxj:'7,500'},
        {id:'2',yname:'リベルサス',yml:'7mg/30錠',yyj:'17,820',yxj:'17,600'},
        {id:'3',yname:'リベルサス',yml:'14mg/30錠',yyj:'32,780 ',yxj:'32,600'},
        {id:'4',yname:'カナグル',yml:'100mg/30錠',yyj:'12,100',yxj:'12,100'},
        {id:'5',yname:'防風通聖散',yml:'90包/30日分',yyj:'6,100',yxj:'5,300'},
      ],
      showIndex: 1
    }
  },
  methods:{
    changeNav(val){
      this.showIndex = val.id
    },
    download(path, title) {
      let a = document.createElement('a')
      a.setAttribute('href', path)
      a.setAttribute('download', title)
      document.body.appendChild(a)
      a.click()
    }
  }
}
</script>

<style scoped>
/* PC */
a{
  text-decoration: line-through;
  text-decoration-color: #ff4040;
}
ol{
  list-style-type: disc;
  margin-left: 1rem;
}
.hidden-sm-and-down .nav{
  display: flex;
  padding: 0 20px;
}
.hidden-sm-and-down .nav li{
  padding: 15px 10px;
  cursor: pointer;
}
.hidden-sm-and-down .nav li:hover,
.active{
  color: #7887d9;
  border-bottom: 2px solid #7887d9;
}
.detail1 {
  margin: 40px 20px;
}

.content1 .qr-code {
  margin-left: 40px;
}

.link{
  color: #7887d9;
  text-align: center;
  cursor: pointer;
}

.detail1 .img{
  position: relative;
  margin: 30px 0;
}
.detail1 .img p{
  position: absolute;
  z-index: 99;
  top: 24%;
  left: 5%;
  line-height: 2.5;
  font-weight: bold;
  color: #0a4a6e;
  text-shadow: #c8c8c8 1px 1px 0px, #b4b4b4 0px 2px 0px;
} 
.content1 {
  margin-top: 30px;
}

.content1 .title1 {
  color: #e88383;
  margin-bottom: 10px;
}
.content1 .text1 {
  color: #535353;
  line-height: 1.5;
}
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

/* ダイエット */

.content1 .text{
  display: flex;
}
.content1 .text p{
  margin: 0 0;
}
.q{color: #1285c6;}
.a{color: #ff4040;}

.content1 .jgb{
  color: #0a4a6e;
  font-size: 24px;
}
.content1 .jgb li{
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: #d4cac9 2px dashed;
}
.content1 .jgb li div{
  width: 33.3%;
}
.content1 .jgb .y_name{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 26%;
}
.content1 .jgb li div:last-child{
  width: 35%;
}

.content1 .jgb span:last-child{
  font-size: 16px;
  padding-bottom: 10px;
}

.content1 .jgb .y_num span{ 
  font-size: 16px;
  margin-left: 20px;
}
.jt{
  font-size: 20px; 
  margin-right: 20px;
}

.tdimg{
  display: flex;
  margin-top: 30px;
}
.tdimg .el-image{
  width: 100%;
}


/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #7887d9;
  border-bottom: 2px solid #7887d9;
  padding: 2rem 0;
}
.m-nav{
  display: flex;
  padding: 0 10px;
}
.m-nav li{
  margin-right: 8px;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #e88383;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}

.m-tdimg{
  display: flex;
  margin-top: 30px;
}
.m-tdimg .el-image{
  width: 100%;
}

.m-detail .m-img{
  position: relative;
  margin: 30px 0;
}
.m-detail .m-img p{
  position: absolute;
  z-index: 99;
  top: 24%;
  left: 3%;
  font-size: 12px;
  line-height: 2.5;
  font-weight: bold;
  color: #0a4a6e;
  text-shadow: #c8c8c8 1px 1px 0px, #b4b4b4 0px 2px 0px;
}

.m-content .jgb{
  color: #0a4a6e;
  font-size: 16px;
}
.m-content .jgb li{
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: #d4cac9 2px dashed;
}
.m-content .jgb li div{
  width: 33.3%;
}
.m-content .jgb .y_name{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 26%;
}
.m-content .jgb li div:last-child{
  width: 38%;
}

.m-content .jgb span:last-child{
  font-size: 16px;
  padding-bottom: 10px;
}

.m-content .jgb .y_num span{ 
  font-size: 14px;
  margin-left: 10px;
}
.m-content  .jt{
 font-size: 14px; 
 margin-right: 10px;
}

.m-content .m-content{
  display: flex;
}

.m-content .m-content p{
  margin: 0 0;
}
.m-content .m-bot{
  padding-bottom: 10px;
}
.m-qr-code {
  text-align: center;
}
</style>
